import React, { Component } from "react";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import axios from "axios";

import "./App.css";
import BrandService from "./services/brandService";

import Login from "./components/login";
import Menu from "./components/menu";
import Logout from "./components/logout";
import NotFound from "./components/notfound";
//addimport 20
import UnderMaintenance from "./components/UnderMaintenance";

class App extends Component {
  state = {
    brandPrefix: "hightower",
  };

  constructor(props) {
    super(props);
    this.onAuthRequired = this.onAuthRequired.bind(this);
    this.restoreOriginalUri = this.restoreOriginalUri.bind(this);
    // instert line 32
    this.CheckMaintenance = this.CheckMaintenance.bind(this);

    this.redirectUri = window.location.origin + "/implicit/callback";

    this.config = {
      authUrl: process.env.REACT_APP_AUTH_BASE_URL,
      clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
      issuer: process.env.REACT_APP_AUTH_BASE_URL + "/oauth2/default",
      redirectUri: this.redirectUri,
      onAuthRequired: this.onAuthRequired,
    };

    this.oktaAuth = new OktaAuth(this.config);
  }

  async componentDidMount() {
    LogRocket.init("mgxe1z/client-portal", {
      network: {
        requestSanitizer: (request) => {
          if (request.url.toLowerCase().indexOf("/authn") >= 0) {
            if (request && request.body) request.body = null;
          }
          return request;
        },
      },
    });
    setupLogRocketReact(LogRocket);
    //insert line 59
    this.CheckMaintenance();
    const brand = BrandService.getBrandClassPrefix(window.location.host);
    this.setState({ brandPrefix: brand });
    console.log(`${brand} ${process.env.NODE_ENV} ${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`);
  }

  onAuthRequired() {
    this.props.history.push("/login");
  }
  //insert function from 70 - 76
  CheckMaintenance() {
    this.maintmode = false;
    if (!this.maintmode) {
      this.setState({ render: true });
    } else {
      const IPAddress = [
        "76.128.177.35",
        "76.141.242.118",
        "144.121.164.83",
        "3.213.200.159",
        "20.29.8.193",
        "144.121.164.87",
        "172.58.164.198",
        "38.140.193.186",
        "144.121.164.83",
        "204.14.37.80",
        "10.0.0.30",
        "98.24.36.87",
      ];
      // const IPAddress = ["38.140.193.186", "72.255.1.215", "22.333.444.555", "33.444.555.666", "112.196.159.195"];
      axios.get("https://api.ipify.org?format=json").then((response) => {
        console.log(response.data.ip);
        this.setState({ render: IPAddress.indexOf(response.data.ip) !== -1 });
      });
    }
    console.log(this.state.render);
  }
  async restoreOriginalUri(_oktaAuth, originalUri) {
    this.props.history.replace(toRelativeUrl(originalUri, window.location.origin));
  }

  render() {
    return (
      <>
        {this.state.render ? (
          <React.Fragment>
            <Security oktaAuth={this.oktaAuth} onAuthRequired={this.config.onAuthRequired} restoreOriginalUri={this.restoreOriginalUri} tokenManager={{ storage: "sessionStorage" }}>
              <div className={`container-fluid full-height ${this.state.brandPrefix}-background`}>
                <div className="row">
                  <Switch>
                    <Route
                      path="/login"
                      exact
                      render={(props) => (
                        <Login
                          brandPrefix={this.state.brandPrefix}
                          authUrl={this.config.authUrl}
                          authClientId={this.config.clientId}
                          redirectUri={this.redirectUri}
                          issuer={this.config.issuer}
                          {...props}
                        />
                      )}
                    />
                    <SecureRoute path="/menu" exact render={(props) => <Menu brandPrefix={this.state.brandPrefix} authUrl={this.config.authUrl} {...props} />} />
                    <Route path="/logout" exact component={Logout} />
                    <Route path="/implicit/callback" exact component={LoginCallback} />
                    <Route path="/not-found" exact component={NotFound} />
                    <Redirect from="/" exact to="/menu" />
                    <Redirect exact to="/not-found" />
                  </Switch>
                </div>
              </div>
            </Security>
          </React.Fragment>
        ) : (
          <UnderMaintenance />
        )}
      </>
    );
  }
}

export default withRouter(App);
